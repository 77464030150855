@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.home {
    /* background: linear-gradient(180deg, #8454F4 0%, #192442 100%); */
    background: 'white';
    color: white;
    position: relative;
}

.containerTextHeader {
    background-size: cover;
    background-repeat: no-repeat;
    height: 1190px;
    position: absolute;
    left: 0;
    top: 0;
}

.containerBenefits {
    display: flex;
    justify-content: start;
    align-items: center;
    /* height: 100vh; */
    /* margin-bottom: 10vh; */
}

.imgBenefits {
    width: 20vh;
    margin-right: 4vh;
    margin-left: 4vh;
}

.textBenefit {
    font-family: 'Nunito', sans-serif;
    font-size: 3vh;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
}

.titleInstructions {
    font-family: Monospace;
    color: rgba(251, 129, 190, 1);
    font-size: 8vh;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 4vh;
    margin-bottom: 15vh;
}

.containerInstructions {
    width: 27vh;
}

.imgNumberInstructions {
    width: 12vh;
}

.textInstructions {
    font-family: 'Nunito', sans-serif;
    font-size: 3vh;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    /* text-align: left; */
}

.rowInstructions {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.imgArrowInstructions {
    width: 45vh;
}

.containerFooterHome {}

.imgOneFooterHome {}

.containerTextFooterHome {
    position: relative;
}

.boxTextFooterHome {
    position: absolute;
}

.titleFooterhome {
    font-family: 'Nunito' sans-serif;

}


.imgTwoFooterHome {
    width: 100%;
}

.titleTextHeader {
    font-weight: 700;
}

.textHeader {
    font-size: 4vh;
    font-weight: 400;
}


.boxTextHeader {}

.button {
    background-color: rgb(69, 71, 211);
    width: 500px;
    height: 60px;
    border-radius: 27px;
    font-size: 30px;
    border: none;
    font-family: Monospace !important;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.button:active {
    background-color: #ddd;
    transform: scale(0.95);
}


@media (max-width: 767px) {
    .imgPentagono {
        width: auto;
        /* O cualquier otro estilo que desees en pantallas más pequeñas */
    }

    .titleTextHeader {
        font-size: 9.5vw;
        margin-top: 22vh;
        margin-bottom: 5.5vh;
        margin-left: 90px;
        /* line-height: 50px; */
        letter-spacing: 4px;
        text-align: center;
    }

    .textHeader {
        text-align: justify;
        font-size: 3vh;
        font-weight: 400;
        display: none;
    }

    .boxTextHeader {
        width: 70vw;
        margin-left: 80px;
    }

    .button {
        background-color: rgb(69, 71, 211);
        width: 250px;
        height: 60px;
        border-radius: 27px;
        font-size: 30px;
        border: none;
        font-family: Monospace !important;
        font-weight: 700;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
        margin-left: 85px;
    }

    .titleInstructions {
        font-size: 6vh;
    }

    .rowInstructions {
        flex-direction: column !important;
        text-align: center !important;
    }

    .imgNumberInstructions {}

    .imgArrowInstructions {
        /* transform: rotate(90deg); */
        display: none;
    }

    .containerInstructions {
        width: 27vh;
    }

    .textInstructions {
        text-align: center;
        margin-bottom: 8vh;
    }

    .imgOneFooterHomeOne {
        width: 110%;
    }

    .imgOneFooterHomeTwo {
        width: 100%;
    }

    .containerTextFooterHome {
        position: relative;
    }

    .boxTextFooterHome {
        position: absolute;
        bottom: 65px;
        left: 15px
    }

    .titleFooterhome {
        font-size: 4vh;
        font-weight: 700;
        letter-spacing: 4px;
        text-align: left;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .textFooterhome {
        font-family: 'Nunito' sans-serif;
        text-align: start;
        font-size: 2.5vh;
        font-weight: 400;
        margin: 0;
    }

    .textFooterhomeTwo {
        font-family: Monospace;
        font-size: 2.5vh;
        font-weight: 700;
        text-align: start;
        color: rgba(251, 129, 190, 1);
        margin: 0;
    }

    .textFooterhomeThree {
        font-family: 'Nunito' sans-serif;
        font-size: 2.5vh;
        font-weight: 400;
        text-align: start;
        margin-bottom: 0;
        color: rgba(255, 255, 255, 0.6);
        margin: 0;
    }

}

/* Media query para dispositivos más grandes, por ejemplo, computadoras de escritorio */
@media (min-width: 768px) {
    .imgPentagono {
        width: 100%;
    }

    .titleTextHeader {
        font-size: 10vw;
        margin-top: 13vh;
        margin-bottom: 1vh;
        letter-spacing: 7px;
        text-align: center;
    }

    .textHeader {
        text-align: justify;
        font-size: 4vh;
        font-weight: 400;
    }

    .boxTextHeader {
        width: 60vw;
    }

    .rowInstructions {
        flex-direction: row !important;
    }

    .containerInstructions {
        width: 27vh;
    }

    .textInstructions {
        text-align: left;
    }

    .imgOneFooterHomeOne {
        width: 120%;
    }

    .containerTextFooterHome {
        position: relative;
    }

    .containerFooterHomeTwo {
        position: relative;
    }

    .imgOneFooterHomeTwo {
        position: absolute;
        width: 116%;
        right: 1px;
        bottom: 5px;
    }

    .boxTextFooterHome {
        position: absolute;
        bottom: 115px;
        left: 55px
    }

    .titleFooterhome {
        font-size: 8vh;
        font-weight: 700;
        letter-spacing: 4px;
        text-align: left;
        margin-top: 0;
        margin-bottom: 25px;
    }

    .textFooterhome {
        font-family: 'Nunito' sans-serif;
        text-align: start;
        font-size: 3vh;
        font-weight: 400;
    }

    .textFooterhomeTwo {
        font-family: Monospace;
        font-size: 3vh;
        font-weight: 700;
        text-align: start;
        color: rgba(251, 129, 190, 1);
    }

    .textFooterhomeThree {
        font-family: 'Nunito' sans-serif;
        font-size: 3vh;
        font-weight: 400;
        text-align: start;
        margin-bottom: 0;
        color: rgba(255, 255, 255, 0.6);
        margin: 0;
    }
}




/* -------------------------------------- */


.main-image-container {
    width: 100%;
    height: 500px;
    /* Ajusta la altura según tus necesidades */
    margin-bottom: 20px;
}

.main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.thumbnail-slider {
    margin-top: 20px;
}

.thumbnail-image {
    width: 100%;
    height: 100px;
    /* Ajusta la altura según tus necesidades */
    object-fit: cover;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

.thumbnail-image:hover {
    opacity: 0.8;
}